.App{ 
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  height: 100vh;
}  

.cream{
  color: #D09E6F; 
}

.primary{
  color: #1D3745; 
}
.lightgrey{
  color: #9C9C9C; 
}

