@font-face {
  font-family: "filson-pro";
  src: url("https://use.typekit.net/af/c12453/0000000000000000000157bf/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c12453/0000000000000000000157bf/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c12453/0000000000000000000157bf/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "filson-pro";
  src: url("https://use.typekit.net/af/fcca1c/0000000000000000000157c8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/fcca1c/0000000000000000000157c8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/fcca1c/0000000000000000000157c8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "filson-pro";
  src: url("https://use.typekit.net/af/19c742/0000000000000000000157c9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/19c742/0000000000000000000157c9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/19c742/0000000000000000000157c9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "sofia-pro";
  src: url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  overflow-x: hidden;
  padding: 0;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  font-family: "filson-pro", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wpwl-group-brand,
.wpwl-label {
  display: none !important;
}

.wpwl-form-card {
  width: 100%;
  max-width: 100%;
  padding: 0;
  background-image: none;
  background-color: #fff;
  box-shadow: none;
  border: none;
}

.wpwl-control {
  width: 100%;
  padding: 0 20px;
  height: 54.56px;
  background: #fff;
  border: 2px solid #1d3745;
  box-sizing: border-box;
  border-radius: 118.607px;
  outline: none;
  font-weight: bold;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.13em;
  color: #1d3745;
}

.wpwl-control:focus {
  border: 2px solid #d09e6f;
  color: #d09e6f;
}

.wpwl-button-pay {
  width: 100%;
  height: 54.56px;
  background: #1d3745;
  border-radius: 118.607px;
  font-weight: bold;
  font-size: 20.1632px;
  line-height: 20px;
  letter-spacing: 0.13em;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.wpwl-button-pay:active {
  transform: scale(0.99);
}

@media (max-width: 600px) {
  .wpwl-control {
    height: 34.12px;
    font-size: 12px;
    line-height: 12px;
  }

  .wpwl-group {
    font-size: 12px;
    line-height: 12px;
  }

  .wpwl-button-pay {
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    height: 42.36px;
    margin-top: 10px;
  }

  .wpwl-form {
    margin-bottom: 0;
  }
}

.toastProgress {
  background: #d09e6f !important;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}

@media (max-width: 600px) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.wpwl-registration {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: auto;
}

.wpwl-wrapper-registration-holder,
.wpwl-wrapper-registration-expiry {
  display: none !important;
}

.wpwl-wrapper-registration-number {
  position: relative !important;
}

.wpwl-wrapper-registration-details {
  width: 500px !important;
}

.wpwl-wrapper-registration-number:before {
  content: "Ending in";
}

.wpwl-group-registration {
  border: 2px solid #1d3745;
  border-color: #1d3745 !important;
  border-radius: 20px;
}

@media (max-width: 600px) {
  .wpwl-registration {
    height: auto;
  }
  .wpwl-group-registration {
    border: 1px solid #1d3745;
    border-radius: 15px;
  }
}
